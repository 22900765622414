import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Button, LinkButton } from '@/atoms/Button'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { ParagraphSM } from '@/atoms/Text'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { FloatingCallToActionType } from '@/services/CmsService/CallToAction'
import { GuildPermissions } from '@/services/GuildUserService'
import { useSafeTrack } from '@/utils/analytics'

interface FloatingCallToActionDisplayProps {
  floatingCta: FloatingCallToActionType
  onClick: () => void
  position?: 'left' | 'right'
  isGuildMember?: boolean
  guildPermissions?: GuildPermissions
}

export const FloatingCallToActionDisplay: React.FC<FloatingCallToActionDisplayProps> = ({
  floatingCta,
  position = 'left',
  isGuildMember,
  guildPermissions,
}) => {
  const track = useSafeTrack()
  const [showTrailer, setShowTrailer] = React.useState(true)

  const handleCloseClick = useCallback(() => {
    setShowTrailer(false)
  }, [])

  const trailerUrl = floatingCta?.trailerUrl ? `${floatingCta.trailerUrl}?autoplay=1&mute=1` : null

  const handleClick = useCallback(() => {
    track('Floating CTA Clicked')
  }, [track])

  return (
    <div
      className={classNames(
        'fixed inset-x-0 bottom-0 z-40 flex flex-col items-center max-w-full group-floating-cta',
        position === 'left' ? 'md:left-10 w-full' : 'md:right-10 md:left-auto md:flex-row-reverse',
      )}
    >
      {trailerUrl && showTrailer && (
        <div className="relative hidden h-full w-full md:block">
          <Button
            onClick={handleCloseClick}
            className="absolute -left-0 -top-8 z-40 rounded-full p-1 opacity-0 group-floating-cta-hover:opacity-100 hover:opacity-70"
            variant="plain"
          >
            <CircleCloseIcon size={24} />
          </Button>
          <EmbeddedVideo
            url={trailerUrl}
            className="mb-2 hidden h-full w-full md:block"
            iframeStyle={{ borderRadius: '16px' }}
          />
        </div>
      )}
      <div
        className={classNames(
          'flex items-center justify-between gap-4 bg-black px-3 py-4 sm:px-6 md:bottom-10 md:min-w-[360px] md:rounded-xl w-full se:py-2',
        )}
      >
        <div className="flex flex-col items-center justify-center gap-0">
          {floatingCta?.logoAltText && floatingCta?.logoUrl && (
            <Image
              alt={floatingCta?.logoAltText}
              src={floatingCta?.logoUrl}
              layout="intrinsic"
              width={floatingCta?.logoWidth ?? 120}
              height={floatingCta?.logoHeight ?? 56}
            />
          )}
          <ParagraphSM className="se:photon-paragraph-xs whitespace-nowrap text-white" weight="bold">
            {floatingCta?.title}{' '}
            <span style={{ color: floatingCta?.descriptionColor ?? 'red' }}>{floatingCta?.description}</span>
          </ParagraphSM>
        </div>
        <LinkButton
          className="flex items-center justify-center gap-2"
          href={floatingCta?.buttonHref ?? '/tickets'}
          outline
          onClick={handleClick}
          variant="white"
        >
          {floatingCta?.buttonIcon === 'ticket' && <TicketIcon color="current" />}
          <span className="whitespace-nowrap text-sm sm:text-base">
            {isGuildMember && guildPermissions?.hasFreeTickets && floatingCta?.guildButtonText
              ? floatingCta.guildButtonText
              : floatingCta?.buttonText}
          </span>
        </LinkButton>
      </div>
    </div>
  )
}
