import React from 'react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

export function GiftGuildButton(props: { onClick?: () => void; className?: string }) {
  const { t } = useTranslate('common')
  const track = useSafeTrack()

  function handleClick() {
    track('Gift Guild CTA Clicked')
    props.onClick?.()
  }

  return (
    <LinkButton
      className={classNames(
        'py-3 px-6 md:w-fit fill-white hover:fill-black backdrop-blur-md whitespace-nowrap',
        props.className,
      )}
      href={`https://shop.angel.com/products/angel-guild-premium-gift-membership-yearly?selling_plan=5245042919`}
      onClick={handleClick}
      variant="white"
      outline
    >
      <div className="relative flex w-full flex-row items-center justify-center">
        <GiftIcon size={22} color="current" className="fill-inherit" />
        <div className="ml-2 mt-1">{t('giftTheGuild', 'Gift the Guild')}</div>
      </div>
    </LinkButton>
  )
}
