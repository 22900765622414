import { useCallback, useState } from 'react'
import { When } from 'react-if'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { Image } from '@/atoms/Image'
import { FloatingCallToActionType } from '@/services/CmsService/CallToAction'
import { GuildPermissions } from '@/services/GuildUserService'
import { buildCloudinaryImagePathFromContentfulObject } from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'

interface FloatingCallToActionImageProps {
  floatingCta: FloatingCallToActionType
  onClick: () => void
  isGuildMember: boolean
  guildPermissions?: GuildPermissions
}

export const FloatingCallToActionImage: React.FC<FloatingCallToActionImageProps> = ({
  floatingCta,
  onClick,
  isGuildMember,
  guildPermissions,
}) => {
  const [showCta, setShowCta] = useState(true)
  const track = useSafeTrack()

  const handleClick = useCallback(() => {
    track('Floating CTA Clicked')
    onClick()
  }, [onClick, track])

  const handleClose = () => {
    setShowCta(false)
  }

  return (
    <When condition={showCta}>
      <div className="group-floating-cta fixed inset-x-0 bottom-0 z-40 max-h-[350px] max-w-[350px] gap-4 bg-transparent shortDesktop:max-h-[250px] shortDesktop:max-w-[250px]">
        <div className="relative">
          <When condition={isGuildMember && guildPermissions?.hasFreeTickets}>
            {floatingCta?.guildBackgroundImage?.length ? (
              <Image
                src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.guildBackgroundImage)}
                alt={floatingCta?.description ?? 'Floating Call to Action'}
                height={350}
                className="pointer-events-none cursor-default"
                width={350}
              />
            ) : (
              floatingCta?.backgroundImage?.length && (
                <Image
                  src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.backgroundImage)}
                  alt={floatingCta?.description ?? 'Floating Call to Action'}
                  height={350}
                  className="pointer-events-none cursor-default"
                  width={350}
                />
              )
            )}
          </When>
          <When condition={!isGuildMember && !guildPermissions?.hasFreeTickets}>
            {floatingCta?.backgroundImage?.length && (
              <Image
                src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.backgroundImage)}
                alt={floatingCta?.description ?? 'Floating Call to Action'}
                height={350}
                className="pointer-events-none cursor-default"
                width={350}
              />
            )}
          </When>
          <a
            href={floatingCta?.buttonHref ?? '/tickets'}
            className={'absolute bottom-[2px] left-1 h-10 w-36 cursor-pointer'}
            onClick={handleClick}
          />
          <a
            href={floatingCta?.buttonHref ?? '/tickets'}
            className={
              'absolute -bottom-20 -left-60 h-[262px] w-[600px] rotate-[0.6558rad] cursor-pointer shortDesktop:h-[195px]'
            }
            onClick={handleClick}
          />
          <div
            className="absolute left-2 top-14 z-[5000] cursor-pointer rounded-full bg-black opacity-0 transition-all duration-300 ease-in-out group-floating-cta-hover:opacity-100 hover:opacity-70"
            onClick={handleClose}
          >
            <CircleCloseIcon color="core-gray-500" size={24} className="cursor-pointer" />
          </div>
        </div>
      </div>
    </When>
  )
}
