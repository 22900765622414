import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'

interface FullScreenConfettiProps {
  recycle?: boolean
  guildIcon?: boolean
}

export const FullScreenConfetti: React.FC<FullScreenConfettiProps> = ({ recycle, guildIcon }) => {
  const [{ height, width }, setDimensions] = useState({
    height: 0,
    width: 0,
  })

  const GuildIconMonoPath1 =
    'M19.5 3H4.5C3.94772 3 3.5 3.445 3.5 3.99728V11C3.5 15 6.6875 19.5 12 21C17.3125 19.5 20.5 15 20.5 11V3.99747C20.5 3.44519 20.0523 3 19.5 3Z'
  const GuildIconMonoPath2 =
    'M12.009 10.187H15.2743C15.3367 10.1922 15.3992 10.223 15.428 10.2745C15.4712 10.3208 15.5 10.3876 15.5 10.4596V14.9806C15.5 15.0166 15.4904 15.0577 15.476 15.0937C15.4616 15.1297 15.4376 15.1606 15.4088 15.1863C14.41 15.7418 13.2959 16.0195 12.1675 15.9989C9.57443 15.9321 7.5 13.777 7.5 10.9997C7.5 8.22746 9.57443 5.96442 12.1675 6.00042C13.2623 6.03643 14.4724 6.55075 15.3607 7.41482C15.3896 7.4354 15.4136 7.46625 15.428 7.49711C15.4472 7.52797 15.4568 7.56398 15.4568 7.59998C15.4568 7.63598 15.452 7.67199 15.4376 7.70799C15.4232 7.73885 15.404 7.76971 15.38 7.79542L14.3667 8.93209C14.3139 8.96809 14.2515 8.98866 14.1891 8.98866C14.1267 8.98866 14.0642 8.96809 14.0114 8.93209C13.5168 8.41262 12.9358 8.22232 12.3067 8.20689C10.7269 8.17088 9.7473 9.46699 9.7473 10.9842C9.7473 12.5169 10.7269 13.7565 12.3211 13.7925C12.7149 13.8028 13.1038 13.741 13.4784 13.6022V12.1929C13.1903 12.1775 12.2588 12.1775 11.9994 12.1775H11.9994C11.9658 12.1775 11.937 12.1723 11.9034 12.1621C11.8745 12.1466 11.8457 12.1312 11.8217 12.1055C11.7977 12.0798 11.7785 12.0489 11.7641 12.018C11.7497 11.9872 11.7449 11.9512 11.7449 11.9152V10.4442C11.7449 10.4082 11.7497 10.3722 11.7641 10.3413C11.7785 10.3105 11.7977 10.2796 11.8217 10.2539C11.8457 10.2282 11.8746 10.2076 11.9082 10.1973C11.9418 10.187 11.9754 10.1819 12.009 10.187Z'

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    if (height === 0 && width === 0) {
      handleResize()
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [height, width])
  return (
    <Confetti
      width={width}
      height={height}
      recycle={recycle}
      drawShape={
        guildIcon
          ? (ctx) => {
              const path1 = new Path2D(GuildIconMonoPath1)
              const path2 = new Path2D(GuildIconMonoPath2)

              ctx.lineWidth = 2
              ctx.stroke(path1)

              ctx.fill(path2)
            }
          : undefined
      }
    />
  )
}
