import React, { PropsWithChildren } from 'react'
import { logger } from '@/utils/logging'
import { AppFeatures } from '../app-features'
import { useGBExperiment } from '../hooks'
import { Props as VariationProps } from './ExperimentVariation'

// This borrows the implementation from Optmizely's Experiment component that loops through
// `children` to find the matching variation.
//  https://github.com/optimizely/react-sdk/blob/master/src/Experiment.tsx

export interface Props<T extends keyof AppFeatures> extends PropsWithChildren {
  experimentName: T
  defaultValue: AppFeatures[T]
}

export function Experiment<T extends keyof AppFeatures>({ experimentName, defaultValue, children }: Props<T>) {
  const [variation, clientReady] = useGBExperiment(experimentName, defaultValue)

  if (!clientReady) {
    // Only block rendering while were waiting for the client
    return null
  }

  let defaultMatch: React.ReactElement<VariationProps> | null = null
  let variationMatch: React.ReactElement<VariationProps> | null = null

  // We use React.Children.forEach instead of React.Children.toArray().find()
  // here because toArray adds keys to all child elements and we do not want
  // to trigger an unmount/remount
  //@ts-expect-error `children` type needs work
  React.Children.forEach(children, (child: React.ReactElement<VariationProps>) => {
    if (!React.isValidElement(child)) {
      return
    }

    const { default: isDefault, variation: childVariation } = child.props

    if (!isDefault && childVariation === variation) {
      variationMatch = child
    }

    if (!isDefault && typeof childVariation !== typeof defaultValue) {
      logger().error(`Invalid variation "${childVariation}" used for experiment: "${experimentName}"`, {
        variation,
        childVariation,
        experimentName,
      })
    }

    // Last child with default prop wins
    if (isDefault) {
      defaultMatch = child
    }
  })

  let match: React.ReactElement<VariationProps> | null = null
  if (variationMatch) {
    match = variationMatch
  } else if (defaultMatch) {
    match = defaultMatch
  }
  return match
}
