import React, { isValidElement, ReactNode } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import classNames from 'classnames'
import { FAQPageJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { AsH2, HeadingXS, ParagraphMD } from '@/atoms/Text'
import { IconColor } from '@/atoms/utils'
import { Accordion, AccordionItem } from '@/molecules/Accordion'
import { options } from '@/services/ContentfulService'
import { Faq } from '@/services/FaqService'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

type CustomFaqs = { question: string; answer: string | ReactNode }[]
interface FaqAccordionProps {
  faqs: Faq[]
  contentElementId?: string
  labelClassName?: string
  iconColor?: IconColor
  showBottomBorder?: boolean
  showTopBorder?: boolean
  borderColor?: string
  rowClassName?: string
  contentClassName?: string
  shouldDefaultOpen?: boolean
  headingClassName?: string
  customFaqs?: CustomFaqs
}

export const FaqAccordion: React.FC<FaqAccordionProps> = ({
  faqs = [],
  contentElementId,
  labelClassName,
  iconColor,
  showBottomBorder,
  showTopBorder,
  borderColor,
  contentClassName,
  shouldDefaultOpen = false,
  headingClassName,
  rowClassName,
  customFaqs,
}) => {
  const { t } = useTranslate('common')
  const track = useSafeTrack()
  const { asPath } = useRouter()
  const hash = (asPath as string).split('#')[1]

  if (!faqs) return null

  return (
    <>
      <When condition={faqs && faqs?.length && faqs.length > 0}>
        <FAQPageJsonLd
          mainEntity={faqs.map((faq) => ({
            questionName: faq.question,
            acceptedAnswerText: documentToPlainTextString(faq.answer?.json),
          }))}
        />
        <HeadingXS id="faq-section-title" as={AsH2} className={classNames('pb-6', headingClassName)} weight="bold">
          {t('frequentlyAskedQuestions', 'Frequently Asked Questions')}
        </HeadingXS>
        <Accordion>
          {faqs.map((faq: Faq) => (
            <AccordionItem
              rowClassName={rowClassName}
              contentElementId={contentElementId}
              labelClassName={labelClassName}
              iconColor={iconColor}
              showBottomBorder={showBottomBorder}
              showTopBorder={showTopBorder}
              label={faq.question}
              key={faq.question}
              borderColor={borderColor}
              contentClassName={contentClassName}
              defaultOpen={Boolean(hash && faq?.hashLink === hash) ?? shouldDefaultOpen}
              hashLink={faq?.hashLink}
              onClick={() => {
                track('Toggle FAQ', {
                  question: faq.question,
                  location: faq.location,
                  project: faq.project?.slug,
                  region: faq.region,
                  regionList: JSON.stringify(faq.regionList),
                })
              }}
            >
              {documentToReactComponents(faq.answer?.json, options)}
            </AccordionItem>
          ))}
          {customFaqs?.map((faq) => {
            return (
              <AccordionItem
                key={faq.question}
                rowClassName={rowClassName}
                contentElementId={contentElementId}
                labelClassName={labelClassName}
                iconColor={iconColor}
                showBottomBorder={showBottomBorder}
                showTopBorder={showTopBorder}
                label={faq.question}
                borderColor={borderColor}
                contentClassName={contentClassName}
              >
                {isValidElement(faq?.answer) ? faq.answer : <ParagraphMD weight="normal">{faq?.answer}</ParagraphMD>}
              </AccordionItem>
            )
          })}
        </Accordion>
      </When>
    </>
  )
}
