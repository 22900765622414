import dynamic, { DynamicOptions } from 'next/dynamic'
import { AppFeatures } from '@/experimentation'
import { logger } from '@/utils/logging'
import { Props } from './FadeInExperiment'

export const FadeInExperiment = dynamic(
  import('./FadeInExperiment')
    .then((mod) => mod.FadeInExperiment)
    .catch((err) => logger().error(`Failed to load the FadeInExperiment component!`, err)) as DynamicOptions<
    Props<keyof AppFeatures>
  >,
  { ssr: false },
)
