import React, { useCallback, useEffect, useState, TouchEvent as ReactTouchEvent, useRef } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { useMediaQuery } from 'react-responsive'
import { LinkButton } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { HeadingXS, LabelLG, ParagraphSM, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants'
import { useRegionContext } from '@/contexts/RegionContext'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { Modal } from '@/molecules/Modal'
import { SimpleThumbnail } from '@/molecules/Thumbnail'
import { useGuildMember } from '@/services/GuildUserService'
import { useSafeTrack } from '@/utils/analytics'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { isUnitedStatesorCanada } from '@/utils/location'
import { getBooleanFromSessionStorage } from '@/utils/session-storage'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import FullScreenConfetti from '../FullScreenConfetti'

export const CelebrateGuildModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const minSwipeDistance = 250
  const ref = useRef<HTMLDivElement | null>(null)
  const { isGuildMember } = useGuildMember()
  const { t } = useTranslate('common')
  const { region } = useRegionContext()
  const isUSorCanada = isUnitedStatesorCanada(region)
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 768px)' })

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
    writeToLocalStorage('hasSeenCelebrateGuildModal', true)
  }, [])

  useEffect(() => {
    // Check if current date is after the cutoff date (March 23, 2025)
    const currentDate = new Date()
    const cutoffDate = new Date('2025-03-23T23:59:59')

    // Don't show after the 24th
    if (currentDate > cutoffDate) {
      return
    }

    // Was originally set in sessionStorage, but it was persisting across browsers so moved to localStorage
    const hasSeenModal =
      getBooleanFromSessionStorage('hasSeenCelebrateGuildModal') ||
      getBooleanFromLocalStorage('hasSeenCelebrateGuildModal')

    if (!hasSeenModal) {
      setIsModalOpen(true)
    }
  }, [])

  const onTouchStart = useCallback((event: ReactTouchEvent) => {
    setTouchEnd(0)
    setTouchStart(event.targetTouches[0].clientY)
  }, [])

  const onTouchMove = useCallback((event: ReactTouchEvent) => setTouchEnd(event.targetTouches[0].clientY), [])

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return
    const html = window.document.getElementsByTagName('html')[0]
    html.style.overflow = 'hidden'
    const distance = touchStart - touchEnd
    const isSwipeDown = distance < -minSwipeDistance
    if (isModalOpen && isSwipeDown) {
      handleModalClose()
      html.style.overflow = 'auto'
    }
  }, [handleModalClose, isModalOpen, touchStart, touchEnd])

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (isModalOpen && ref.current && !ref.current.contains(event.target as Node)) {
        handleModalClose()
      }
    },
    [ref, handleModalClose, isModalOpen],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    if (isModalOpen) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [isModalOpen])

  if (!isUSorCanada) return null

  return (
    <LazyHydrate whenVisible>
      <>
        <When condition={isDesktopOrLaptop}>
          <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            panelClasses="md:min-w-[88vw] lg:min-w-min !p-4 !pb-8 !max-w-[834px]"
            className=""
          >
            <GuildModalBody isGuildMember={isGuildMember} t={t} handleModalClose={handleModalClose} />
          </Modal>
        </When>
        <When condition={!isDesktopOrLaptop}>
          {isVisible && (
            <div
              className={classNames(
                'fixed inset-0 z-[1999]  backdrop-opacity-70 transition-all',
                isVisible ? 'bg-core-gray-950/70' : 'bg-transparent',
              )}
            />
          )}
          <div
            ref={ref}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            className={classNames(
              'box-border fixed bottom-0 left-0 z-[2000] max-h-[90vh] w-full rounded-t-3xl border-t bg-core-gray-100 transition-all duration-300 ease-in-out',
              'sm:max-w-[400px] sm:right-0 sm:mx-auto sm:rounded-b-3xl sm:overflow-hidden sm:h-fit',
              isVisible
                ? 'translate-y-0 sm:my-auto sm:bottom-0 sm:top-0 sm:opacity-1'
                : 'translate-y-full sm:opacity-0',
            )}
          >
            <div className="p-4 pb-8">
              <GuildModalBody isGuildMember={isGuildMember} t={t} handleModalClose={handleModalClose} />
            </div>
          </div>
        </When>
      </>
    </LazyHydrate>
  )
}

interface GuildModalBodyProps {
  isGuildMember: boolean
  t: TranslateFunction
  handleModalClose: () => void
}

const GuildModalBody: React.FC<GuildModalBodyProps> = ({ isGuildMember, t, handleModalClose }) => {
  const track = useSafeTrack()
  const [loadPlayer, setLoadPlayer] = useState(false)

  const handleClick = useCallback(() => {
    track('Guild Million Celebration Modal CTA Clicked', isGuildMember)
  }, [isGuildMember, track])

  const handleLoadPlayer = useCallback(() => {
    setLoadPlayer((prev) => !prev)
  }, [])

  return (
    <div className="flex flex-col text-black">
      <FullScreenConfetti recycle={false} guildIcon />
      <div className="flex items-center justify-end">
        <div onClick={handleModalClose} className="cursor-pointer">
          <CloseIcon color="black" size={24} className="cursor-pointer" />
        </div>
      </div>
      <div className="flex flex-col gap-y-4 md:gap-y-6 md:px-6">
        <HeadingXS weight="bold" className="md:photon-heading-sm xl:photon-heading-md">
          {isGuildMember
            ? t('thanksMillionMembersLikeYou', 'Thanks to 1 Million members like you, more things are coming to Angel!')
            : t('moreThingsComing', `More things are coming to Angel!`)}
        </HeadingXS>
        <When condition={!loadPlayer}>
          <SimpleThumbnail
            imageUrl={
              'https://images.angelstudios.com/image/upload/v1741908229/angel-studios/guild/General_YT_Thumbnail_-_2025-03-11T111453.951.jpg'
            }
            showButton
            onClick={handleLoadPlayer}
            className={'rounded-2xl'}
          />
        </When>
        <When condition={loadPlayer}>
          <div className="overflow-hidden rounded-2xl">
            <EmbeddedVideo
              className="w-full"
              title={t('oneMillionAGMembers', 'One Million Angel Guild Members')}
              url={`https://www.youtube.com/embed/nDHh_iqSp7U?autoplay=1&si=t6PJP9JfF2pze-iV`}
            />
          </div>
        </When>
        <div className="flex flex-col items-center gap-y-4 text-center md:gap-y-6">
          <div className="flex flex-row items-center gap-1 lg:gap-2">
            <TitleSM weight="bold" className="md:photon-title-lg">
              {t('thankYouJourneyPossible', 'Thank you for making this journey possible!')}
            </TitleSM>
          </div>
          <ParagraphSM color="core-gray-700" className={classNames(isGuildMember ? 'max-w-[500px]' : 'max-w-[670px]')}>
            {isGuildMember
              ? t(
                  'weHaveReachedOneMillionAngelGuildMembersAmplifyLight',
                  `We've reached 1 million Angel Guild members supporting stories that amplify light. Imagine what we can do with 2, 3, or even 10 million!`,
                )
              : t(
                  'weHaveReachedOneMillionAngelGuildMembersSupporting',
                  `We've reached 1 million Angel Guild members supporting stories that amplify light. Imagine what we can do with 2, 3, or even 10 million! Join the movement revolutionizing the film industry.`,
                )}
          </ParagraphSM>
          <LinkButton
            className="rounded-[14px]px-6 w-fit py-[17px]"
            onClick={handleClick}
            href={
              isGuildMember
                ? 'https://shop.angel.com/products/angel-guild-premium-gift-membership-yearly?selling_plan=5245042919'
                : paths.guild.join
            }
            variant="oxide-primary"
          >
            <LabelLG>
              {isGuildMember ? t('giftTheGuildCta', 'Gift The Guild') : t('joinTheMovementCta', 'Join The Movement')}
            </LabelLG>
          </LinkButton>
        </div>
      </div>
    </div>
  )
}
