export const contentfulFaqLocations = {
  about: 'about',
  browse: 'browse',
  guild: 'guild-signup',
  guildCreate: 'guild-create',
  home: 'home',
  pif: 'pif',
  press: 'press',
  signup: 'signup',
  watch: 'watch',
}
